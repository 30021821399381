import { InjectionToken } from '@angular/core';
import { Routes, ActivatedRouteSnapshot } from '@angular/router';
import { LoginComponent } from './public/components/login/login.component';
import { LogoutComponent } from './public/components/logout/logout.component';
import { ExternRouteComponent } from './public/components/extern-route/extern-route.component';
import { SelfRegistrationComponent } from './public/components/selfRegistration/self-registration.component';
import { UpdatePasswordComponent } from './public/components/update-password/update-password.component';
import { UpdatePasswordSucessPageComponent } from './public/components/update-password-sucess-page/update-password-sucess-page.component';
import { DuplicateRegistrationComponent } from './public/components/duplicateRegistration/duplicate-registration.component';
import { ContactUsComponent } from './public/components/contact-us/contact-us.component';
import { PrivacyStatementComponent } from './public/components/privacy-statement/privacy-statement.component';
import { TermsOfUseComponent } from './public/components/terms-of-use/terms-of-use.component';
var externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
var routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'login/:code',
        component: LoginComponent
    },
    {
        path: 'registration/:partyId',
        component: SelfRegistrationComponent,
    },
    {
        path: 'registration/:partyId/step/:id',
        component: SelfRegistrationComponent,
    },
    {
        path: 'externalRedirect',
        resolve: {
            url: externalUrlProvider
        },
        component: ExternRouteComponent
    },
    {
        path: 'updatePassword',
        component: UpdatePasswordComponent
    },
    {
        path: 'changePassword',
        component: UpdatePasswordComponent
    },
    {
        path: 'updatePassword/successpage',
        component: UpdatePasswordSucessPageComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'logout/:code',
        component: LogoutComponent
    },
    // {
    //   path: 'privacyNotice',
    //   component: PrivacyNoticeComponent
    // },
    {
        path: 'duplicateRegistration',
        component: DuplicateRegistrationComponent
    },
    {
        path: 'contactUs',
        component: ContactUsComponent
    },
    {
        path: 'privacyNotice',
        component: PrivacyStatementComponent
    },
    {
        path: 'termsOfUse',
        component: TermsOfUseComponent
    },
];
var ɵ0 = function (route) {
    var externalURL = route.paramMap.get('externalUrl');
    window.location.replace(externalURL);
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
