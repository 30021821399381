import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "./app-config.service";
import * as i2 from "ngx-cookie-service";
var ManageCookieLogoService = /** @class */ (function () {
    function ManageCookieLogoService(appConfig, cookieService) {
        this.appConfig = appConfig;
        this.cookieService = cookieService;
        /*Available Sites*/
        this.authorizedLocations = ['movepro360', 'alpha', 'transferee', 'supplier', 'benefitsbuilder'];
        /* Okta session timeout */
        this.idleTimeoutMinutes = 55;
        /** domain variable */
        this.domain = '.cartus.com';
    }
    /**
    * To set the cookies in the browser
    * @param res - oktaResponse
    * @param cookieNames - array of cookie names
    */
    ManageCookieLogoService.prototype.setCookies = function (res, cookieNames) {
        var _this = this;
        var sessionTime = new Date().getTime() + (this.idleTimeoutMinutes * 60000).toString();
        cookieNames.forEach(function (cookieName) {
            switch (cookieName) {
                case 'car-ses-tok': {
                    if (_this.appConfig.getConfig('local')) {
                        _this.cookieService.set(cookieName, res.tokens.accessToken.accessToken, undefined, undefined, undefined, undefined, 'Lax'); // Developer
                    }
                    else {
                        _this.cookieService.set(cookieName, res.tokens.accessToken.accessToken, null, // We are relying on Okta session expiration
                        '/', _this.domain, true);
                    }
                    break;
                }
                case 'car-ses-time': {
                    if (_this.appConfig.getConfig('local')) {
                        _this.cookieService.set(cookieName, sessionTime, undefined, undefined, undefined, undefined, 'Lax'); // Developer
                    }
                    else {
                        _this.cookieService.set(cookieName, sessionTime, null, '/', _this.domain, true);
                    }
                    break;
                }
                case 'car-token-claims': {
                    // check if this needs to be set for SSO.
                    if (_this.appConfig.getConfig('local')) {
                        _this.cookieService.set(cookieName, JSON.stringify(res.tokens.idToken.claims), undefined, undefined, undefined, undefined, 'Lax'); // Developer
                    }
                    else {
                        _this.cookieService.set(cookieName, res.tokens.idToken.claims ? JSON.stringify(res.tokens.idToken.claims) : undefined, null, '/', _this.domain, true);
                    }
                    break;
                }
                case 'car-ses-username': {
                    if (!_this.appConfig.getConfig('local')) {
                        _this.cookieService.set(cookieName, res.tokens.idToken.claims.email, null, // We are relying on Okta session expiration
                        '/', _this.domain, true);
                    }
                    break;
                }
                case 'car-token-expiresat': {
                    if (!_this.appConfig.getConfig('local')) {
                        _this.cookieService.set(cookieName, res.tokens.idToken.expiresAt, null, '/', _this.domain, true);
                    }
                    break;
                }
                case 'car-token-idtoken': {
                    if (!_this.appConfig.getConfig('local')) {
                        _this.cookieService.set(cookieName, res.tokens.idToken.idToken, null, '/', _this.domain, true);
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        });
    };
    /**
    * To remove the cookies in the browser
    * @param cookieNames - array of cookie names
    */
    ManageCookieLogoService.prototype.removeCookies = function (cookieNames) {
        var _this = this;
        // work-around for deleting cookies since delete is broken in ngx-cookie-service
        // for in last version compatible with Angular 8
        var oldDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
        cookieNames.forEach(function (cookieName) {
            switch (cookieName) {
                case 'car-ses-tok':
                case 'car-ses-time':
                case 'car-token-claims':
                case 'cas-ses-logo':
                    {
                        if (_this.appConfig.getConfig('local')) {
                            _this.cookieService.set(cookieName, '', oldDate, '/', 'localhost', false, 'Lax');
                        }
                        else {
                            _this.cookieService.set(cookieName, '', oldDate, '/', _this.domain, false, 'Lax');
                        }
                        break;
                    }
                case 'car-ses-username':
                case 'car-token-expiresat':
                case 'car-token-idtoken':
                case 'lastAction':
                    {
                        if (!_this.appConfig.getConfig('local')) {
                            _this.cookieService.set(cookieName, '', oldDate, '/', _this.domain, false, 'Lax');
                        }
                        break;
                    }
                default: {
                    break;
                }
            }
        });
    };
    /**
    * To set the logo for the template based on the referredU
    * @param referrerdURL - source app url
    * @return A response containing the Logo model (object)
    */
    ManageCookieLogoService.prototype.setLogo = function (referredURL) {
        // To hold the logo type
        var logo;
        // Application URLs
        var moveProURL = this.appConfig.getConfig(this.authorizedLocations[0]).toString().split('|');
        var alphaURL = this.appConfig.getConfig(this.authorizedLocations[1]).toString().split('|');
        var transfereeURL = this.appConfig.getConfig(this.authorizedLocations[2]).toString().split('|');
        var vendorURL = this.appConfig.getConfig(this.authorizedLocations[3]).toString().split('|');
        var bbURL = this.appConfig.getConfig(this.authorizedLocations[4]).toString().split('|');
        // /* For MovePro360 Logo */
        if (referredURL === moveProURL[0].replace(/(\/#|\/|#)$/, '') ||
            (moveProURL.length > 1 && referredURL === moveProURL[1].replace(/(\/#|\/|#)$/, ''))) {
            return 'movePro';
        }
        // /* For BB Logo */
        if (!logo && (referredURL === bbURL[0].replace(/(\/#|\/|#)$/, '') ||
            (bbURL.length > 1 && referredURL === bbURL[1].replace(/(\/#|\/|#)$/, '')))) {
            return 'benefitsBuilder';
        }
        // /* For Alpha Logo */
        if (!logo && (referredURL === alphaURL[0].replace(/(\/#|\/|#)$/, '')) ||
            (alphaURL.length > 1 && referredURL === alphaURL[1].replace(/(\/#|\/|#)$/, '')) ||
            (referredURL === transfereeURL[0].replace(/(\/#|\/|#)$/, '')) ||
            (transfereeURL.length > 1 && referredURL === transfereeURL[1].replace(/(\/#|\/|#)$/, ''))) {
            return 'alpha';
        }
        // /* For Supplier Logo */
        if (!logo && (referredURL === vendorURL[0].replace(/(\/#|\/|#)$/, '')) ||
            (vendorURL.length > 1 && referredURL === vendorURL[1].replace(/(\/#|\/|#)$/, ''))) {
            return 'supplier';
        }
    };
    /**
    * To set the logo for the template based on userDetails
    * @param userDetails - User Model object
    * @return Logo string
    *    */
    ManageCookieLogoService.prototype.setLogoFromUser = function (userDetails) {
        if (userDetails.product === 'Alpha') {
            return 'alpha';
        }
        else if (userDetails.product === 'MovePro') {
            return "movePro";
        }
        else if (userDetails.product === 'BenefitsBuilder') {
            return 'benefitsBuilder';
        }
    };
    ManageCookieLogoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManageCookieLogoService_Factory() { return new ManageCookieLogoService(i0.ɵɵinject(i1.AppConfigService), i0.ɵɵinject(i2.CookieService)); }, token: ManageCookieLogoService, providedIn: "root" });
    return ManageCookieLogoService;
}());
export { ManageCookieLogoService };
