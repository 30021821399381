var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { AppConfigService } from './app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
import * as i3 from "ngx-cookie-service";
import * as i4 from "./base-client.service";
import * as i5 from "./remote-logging.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, appConfig, cookieService, baseClient, logSvc) {
        this.http = http;
        this.appConfig = appConfig;
        this.cookieService = cookieService;
        this.baseClient = baseClient;
        this.logSvc = logSvc;
        this.authClient = new OktaAuth({
            clientId: String(this.appConfig.getConfig('oktaClientId')),
            issuer: String(this.appConfig.getConfig('oktaUrl')),
            redirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
            postLogoutRedirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
            tokenManager: {
                storage: 'sessionStorage',
                autoRenew: false
            },
        });
        this.authClient.authStateManager.subscribe(function (authState) {
            // handle emitted latest authState
        });
        this.authClient.authStateManager.updateAuthState();
    }
    /** Component Angular destructor lifecycle hook */
    AuthenticationService.prototype.ngOnDestroy = function () {
        if (this.authClient.authStateManager.subscribe) {
            this.authClient.authStateManager.unsubscribe();
        }
    };
    /** Service call for login */
    AuthenticationService.prototype.login = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var data, _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        data = {
                            username: user.email,
                            password: user.password
                        };
                        _a = this;
                        return [4 /*yield*/, this.authClient.signInWithCredentials(data)];
                    case 1:
                        _a.transaction = _b.sent();
                        return [4 /*yield*/, this.getToken(this.transaction)];
                    case 2: return [2 /*return*/, _b.sent()];
                    case 3:
                        err_1 = _b.sent();
                        return [2 /*return*/, err_1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /** Service call for signOut and revoke */
    AuthenticationService.prototype.signOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // We need to revoke the token before closing it.
                    return [4 /*yield*/, this.authClient.revokeAccessToken()];
                    case 1:
                        // We need to revoke the token before closing it.
                        _a.sent();
                        return [4 /*yield*/, this.authClient.closeSession()
                                .then(function () {
                            })
                                .catch(function (e) {
                                if (e.xhr && e.xhr.status === 429) {
                                    console.error('Too many requests.');
                                }
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Service call for to get tokens */
    AuthenticationService.prototype.getToken = function (transactions) {
        return __awaiter(this, void 0, void 0, function () {
            var receivedTokens;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(transactions && transactions.status === 'SUCCESS')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.authClient.token.getWithoutPrompt({
                                responseType: ['code', 'token', 'id_token'],
                                sessionToken: transactions.sessionToken,
                                scopes: ['openid', 'offline_access', 'email'],
                            })
                                .then(function (res) {
                                receivedTokens = res.tokens;
                                if (receivedTokens.idToken && receivedTokens.accessToken) {
                                    _this.authClient.tokenManager.add('idToken', receivedTokens.idToken);
                                    _this.authClient.tokenManager.add('accessToken', receivedTokens.accessToken);
                                }
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, {
                            transaction: transactions,
                            tokens: receivedTokens
                        }];
                }
            });
        });
    };
    /** Service call for SSO authentication */
    AuthenticationService.prototype.trySso = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authClient.token.getWithoutPrompt().then(function (tokenOrTokens) {
                            if (tokenOrTokens) {
                                _this.authClient.tokenManager.setTokens({
                                    accessToken: tokenOrTokens.tokens.accessToken,
                                    idToken: tokenOrTokens.tokens.idToken
                                });
                            }
                            console.log('getWithoutPrompt()', tokenOrTokens); // Leave this debug code in place
                            return tokenOrTokens;
                        }).catch(function (err) {
                            // console.error(err); // Leave this debug code in place -- commented out due to error being displayed regardless
                            return; // Not authenticated
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /** Call to handle change user password */
    AuthenticationService.prototype.changeUserPassword = function (passwordDetail) {
        var _this = this;
        var urlStr = sessionStorage.getItem('urlPath');
        var appContext;
        if (urlStr) {
            appContext = urlStr.replace(/^(?:[^\/]*\/){2}\s*/, '').slice(0, -1);
        }
        var headers = {
            'app-context': "" + appContext,
            'authorization': this.cookieService.get('car-ses-tok')
        };
        var body = {
            'oldPassword': {
                'value': passwordDetail.oldPassword
            },
            'newPassword': {
                'value': passwordDetail.newPassword
            }
        };
        var url = String(this.appConfig.getConfig('changePasswordEndpoint'));
        return this.baseClient.postNoErrorHandler(url, body, headers).pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            _this.logSvc.logError(err);
            return of(err);
        }));
    };
    /** To reset the incorrect password attempts
     * @param headers for the request
     */
    AuthenticationService.prototype.resetPasswordAttempts = function (headers) {
        var _this = this;
        var url = String(this.appConfig.getConfig('resetPasswordEndpoint'));
        return this.baseClient.post(url, null, headers).pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            var empty = null;
            _this.logSvc.logError(err);
            return of(empty);
        }));
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.CookieService), i0.ɵɵinject(i4.BaseClientService), i0.ɵɵinject(i5.RemoteLoggingService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
