var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { Subscription } from 'rxjs';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { Title } from '@angular/platform-browser';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { RemoteLoggingService } from '../../../core/services/remote-logging.service';
var UpdatePasswordComponent = /** @class */ (function () {
    /**Base Constructor for Login Component
    * @param authSvc Authentication Service to authenticate the user details
    */
    function UpdatePasswordComponent(fb, authSvc, spinner, router, authService, cookieService, userPreferencesService, appConfig, liveAnnouncer, snackBar, titleService, cookieLogoSvc, logSvc) {
        this.fb = fb;
        this.authSvc = authSvc;
        this.spinner = spinner;
        this.router = router;
        this.authService = authService;
        this.cookieService = cookieService;
        this.userPreferencesService = userPreferencesService;
        this.appConfig = appConfig;
        this.liveAnnouncer = liveAnnouncer;
        this.snackBar = snackBar;
        this.titleService = titleService;
        this.cookieLogoSvc = cookieLogoSvc;
        this.logSvc = logSvc;
        /** Subscription property for subscribing and unsubscribing services */
        this.subscription = new Subscription();
        /** Model to inject change password object */
        this.passwordCredential = {};
        /*Token Model initialisation */
        this.token = {};
        /** Password Instructions */
        this.passwordInstructions = ['At least 1 number', 'At least 1 capital letter', 'At least 1 lower case', 'At least 1 special character'];
        /*Available Sites*/
        this.authorizedLocations = ['movepro360', 'alpha', 'transferee', 'vendor', 'benefitsbuilder'];
        /*To check redirected from authorised site or not*/
        this.authorisedSite = false;
        /* Login Button Status*/
        this.loginButtonStatus = true;
        /* Update password error message */
        this.errorMsg = 'You must enter a password';
        /* Okta session timeout */
        this.idleTimeoutMinutes = 55;
        /** Stores the strings used in the template */
        this.templateString = {
            INFORMATION: 'Please use the form below to create a new password so that you can access your account',
            BANNER_TXT: "Technology That Moves You",
            PASSWORD_INSTRUCTION_TITLE: "Passwords must be at least 10 characters, and must contain the following details:",
            PASSWORD_ADDITIONAL_INSTRUCTION: "Additionally, your new password cannot include your username or be one of your last 12 passwords.",
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            confirmNewPassword: 'Confirm New Password',
            CHANGE_PASS_BTN: 'Change Password',
            PASSWORD_NOT_MATCH: 'Password entered does not match new password'
        };
        this.userId = this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.userId
            ? this.router.getCurrentNavigation().extras.state.userId : null;
    }
    // Detect window size
    UpdatePasswordComponent.prototype.onresize = function () {
        this.getWindowSize();
    };
    UpdatePasswordComponent.prototype.ngOnInit = function () {
        var _this_1 = this;
        this.titleService.setTitle('Change Password');
        this.getWindowSize();
        this.createControl();
        this.updateError = '';
        this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(function (val) {
            // Redirect to authorized destination
            _this_1.referredURL = val.replace(/(\/#|\/|#)$/, '');
            var res = _this_1.cookieLogoSvc.setLogo(_this_1.referredURL);
            if (res) {
                _this_1.logo = val;
            }
        });
        // Listener for removal of token cookie sent by header component
        this.checkForCookies();
    };
    /** creates the form controls */
    UpdatePasswordComponent.prototype.createControl = function () {
        this.updatePasswordSetupForm = this.fb.group({
            currentPassword: [null, [Validators.required]],
            newPassword: [null, [Validators.required,
                    Validators.pattern('^((?=.{10,})((?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9\s])(?=.*[0-9]))).*$')]],
            confirmNewPassword: [null, Validators.required]
        });
    };
    /** checks the values of the newpassword fields and displays an error if different */
    UpdatePasswordComponent.prototype.checkPassword = function () {
        this.updateError = '';
        this.updatePasswordSetupForm.get('newPassword').value === this.updatePasswordSetupForm.get('confirmNewPassword').value ?
            this.updatePasswordSetupForm.controls['confirmNewPassword'].setErrors(null) :
            this.updatePasswordSetupForm.controls['confirmNewPassword'].setErrors({
                notSame: true
            });
    };
    /** clears the error status on the field if it has an error */
    UpdatePasswordComponent.prototype.clearError = function (fieldName) {
        if (this.updatePasswordSetupForm.controls[fieldName].hasError('invalid')) {
            this.updateError = '';
            this.updatePasswordSetupForm.controls[fieldName].setErrors(null);
        }
    };
    /** handles the errors on the form controls */
    // getErrorMessage(fieldName): (string | undefined) {
    UpdatePasswordComponent.prototype.getErrorMessage = function (fieldName) {
        if (fieldName === 'currentPassword') {
            return this.updatePasswordSetupForm.get(fieldName).hasError('required')
                ? this.errorMsg
                : '';
        }
        if (fieldName === 'newPassword') {
            return this.updatePasswordSetupForm.get(fieldName).hasError('required')
                ? this.errorMsg
                : this.updatePasswordSetupForm.get(fieldName).hasError('pattern')
                    ? 'You must match password complexity rules'
                    : '';
        }
        if (fieldName === 'confirmNewPassword') {
            return this.updatePasswordSetupForm.get(fieldName).hasError('required')
                ? this.errorMsg
                : '';
        }
    };
    /** handles the submission of the change password form */
    UpdatePasswordComponent.prototype.changeUserPassword = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this;
            var _this_1 = this;
            return __generator(this, function (_a) {
                this.spinner.show();
                _this = this;
                this.passwordCredential.oldPassword = this.updatePasswordSetupForm.controls['currentPassword'].value;
                this.passwordCredential.newPassword = this.updatePasswordSetupForm.controls['newPassword'].value;
                this.subscription.add(this.authSvc
                    .changeUserPassword(this.passwordCredential)
                    .subscribe(function (response) {
                    if (response.error && response.error.statusCode && response.error.statusCode === 403) {
                        var remainingPasswordAttempts = Number(response.error.remainingAttempts);
                        if (response.error.message === 'Unable to change password - your current password is not entered correctly. Please try again') {
                            _this.updatePasswordSetupForm.get('currentPassword').setErrors({
                                notCorrect: true
                            });
                            _this.incorrectPasswordError = "Incorrect password, you will be logged out after " + remainingPasswordAttempts + " more incorrect attempts";
                        }
                        else {
                            _this.updatePasswordSetupForm.get('newPassword').setErrors({
                                invalid: true
                            });
                            _this.updateError = response.error.message + (". you will be logged out after " + remainingPasswordAttempts + " more incorrect attempts");
                            _this.getErrorMessage('newPassword');
                        }
                        if (_this.updateError) {
                            _this.liveAnnouncer.announce(_this.updateError);
                        }
                        if (_this.incorrectPasswordError) {
                            _this.liveAnnouncer.announce(_this.incorrectPasswordError);
                        }
                        if (remainingPasswordAttempts < 1) {
                            _this_1.logout();
                        }
                        _this_1.spinner.hide();
                    }
                    else if (response.error && response.error.statusCode && response.error.statusCode !== 403) {
                        _this_1.logSvc.logError(response);
                        _this_1.spinner.hide();
                        console.error('Error in change password call');
                    }
                    else {
                        var changesSavedSnackbar = _this_1.snackBar.openFromComponent(CustomSnackbarComponent, {
                            horizontalPosition: 'center',
                            verticalPosition: 'bottom',
                            data: 'Changes saved',
                            duration: 5000,
                        });
                        _this_1.spinner.hide();
                        changesSavedSnackbar.afterDismissed().subscribe(function () { return __awaiter(_this_1, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.cancel();
                                return [2 /*return*/];
                            });
                        }); });
                    }
                }));
                return [2 /*return*/];
            });
        });
    };
    /** handles the call to reLogin after a successful password change */
    UpdatePasswordComponent.prototype.reLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return __generator(this, function (_a) {
                this.spinner.show();
                this.token.email = this.cookieService.get('car-ses-username');
                this.token.password = this.passwordCredential.newPassword;
                this.authSvc.login(this.token).then(function (res) {
                    if (!!res.transaction) {
                        if (res.transaction.status === 'SUCCESS' || res.transaction.status === 'MFA_REQUIRED') {
                            _this_1.cookieLogoSvc.removeCookies(['car-ses-tok',
                                'car-ses-time',
                                'car-token-claims',
                                'car-token-expiresat',
                                'car-token-idtoken']);
                        }
                    }
                    _this_1.spinner.hide();
                });
                return [2 /*return*/];
            });
        });
    };
    /** handles the action on clicking the cancel button */
    UpdatePasswordComponent.prototype.cancel = function () {
        var _this_1 = this;
        this.userPreferencesService.getPreference('referrer', false).subscribe(function (val) {
            var url = val;
            _this_1.userPreferencesService.getPreference('urlPath', false).subscribe(function (res) {
                var fullPath = url + "#" + res;
                _this_1.router.navigate(['/externalRedirect', { externalUrl: fullPath }], {
                    skipLocationChange: true,
                });
            });
        });
    };
    /** handles the whether the view is responsive */
    UpdatePasswordComponent.prototype.getWindowSize = function () {
        var windowWidth = window.innerWidth;
        if (windowWidth <= 959) {
            this.responsiveView = true;
        }
        else {
            this.responsiveView = false;
        }
    };
    /** To unsubscribe to the subscription and event listener */
    UpdatePasswordComponent.prototype.ngOnDestroy = function () {
        if (this.prefSub) {
            this.prefSub.unsubscribe();
        }
        clearInterval(this.checkCookieInterval);
    };
    /* performs a redirect to logout */
    UpdatePasswordComponent.prototype.logout = function () {
        this.router.navigate(['logout']);
    };
    /** Check token cookie value status */
    UpdatePasswordComponent.prototype.checkForCookies = function () {
        var _this_1 = this;
        this.checkCookieInterval = setInterval(function () {
            if (!_this_1.cookieService.get('car-ses-tok')) {
                _this_1.logout();
            }
        }, 500);
    };
    return UpdatePasswordComponent;
}());
export { UpdatePasswordComponent };
