import { of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import { mockedDatatovalidateOTP } from 'src/UnitTest-Support/Mocks/data.mock';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
/** user service to post details of login to the server
 * header
 */
var SelfRegistrationValidationService = /** @class */ (function () {
    /**
     * base constructor
     */
    function SelfRegistrationValidationService(baseClient, logSvc) {
        this.baseClient = baseClient;
        this.logSvc = logSvc;
    }
    SelfRegistrationValidationService.prototype.sendOTP = function (user) {
        var _this = this;
        return this.baseClient.post('/otp/generatetotp', user).pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            var empty = null;
            _this.logSvc.logError(err);
            return of(empty);
        }));
    };
    SelfRegistrationValidationService.prototype.validateOTP = function (user) {
        var _this = this;
        return this.baseClient.post('/otp/validatetotp', user).pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            var empty = null;
            _this.logSvc.logError(err);
            return of(empty);
        }));
        return of(mockedDatatovalidateOTP);
    };
    SelfRegistrationValidationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SelfRegistrationValidationService_Factory() { return new SelfRegistrationValidationService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService)); }, token: SelfRegistrationValidationService, providedIn: "root" });
    return SelfRegistrationValidationService;
}());
export { SelfRegistrationValidationService };
