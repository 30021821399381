import { of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
/** user service to post details of login to the server
 * header
 */
var SelfRegistrationSetupService = /** @class */ (function () {
    function SelfRegistrationSetupService(baseClient, logSvc) {
        this.baseClient = baseClient;
        this.logSvc = logSvc;
    }
    SelfRegistrationSetupService.prototype.registerUser = function (request) {
        return this.baseClient.postNoErrorHandler('/user/register', request).pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            return of(err);
        }));
    };
    SelfRegistrationSetupService.prototype.getUser = function (userId) {
        var _this = this;
        return this.baseClient.getById('/user/' + userId).pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            var empty = null;
            _this.logSvc.logError(err);
            return of(empty);
        }));
    };
    SelfRegistrationSetupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SelfRegistrationSetupService_Factory() { return new SelfRegistrationSetupService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService)); }, token: SelfRegistrationSetupService, providedIn: "root" });
    return SelfRegistrationSetupService;
}());
export { SelfRegistrationSetupService };
